<template>
    <div class="policy">
        <p><strong>개인정보 보호정책</strong></p>
        <p><strong>최종 업데이트:</strong> 2024년 10월 31일</p>
        <p><strong>개인정보 보호정책 개요</strong></p>
        <p>클라우드 서비스에 오신 것을 환영합니다. 이 클라우드 서비스는 SMART-PLUS PTE. LTD.(이하 'SMART-PLUS', '당사' 또는 '저희')에서 제공하고 관리합니다. 이 서비스를
            사용함으로써 귀하는 이 개인정보 보호 정책에 동의합니다.</p>
        <p>이 서비스에는 다음이 포함됩니다.</p>
        <p><strong>• </strong>*cloud.akuvox.com, *cloud.akubela.com(*cloud에는 ecloud, ucloud, jcloud, scloud 등이 포함됨)에서
            액세스할 수 있는 당사 웹사이트 및 사용자 계정과 타사 사이트('포털')를 제외한 이와 연결된 모든 관련 사이트;</p>
        <p><strong>• </strong>웹사이트를 통해 액세스할 수 있는 온라인 서비스('웹 서비스');</p>
        <p><strong>• </strong>Akuvox SmartPlus, BelaHome 등과 같이 모바일 기기에 다운로드할 수 있는 소프트웨어 애플리케이션('모바일 앱');</p>
        <p><strong>• </strong>서비스의 관련 제품('기기')에는 도어폰, 실내 모니터, 안드로이드 비디오 폰, 출입 통제 단말기, 홈 제어 패널 등이 포함되지만 이에 국한되지 않습니다.</p>
        <p><strong>• </strong>웹사이트 및 모바일 앱에서 제공되는 모든 서비스('사용 가능한 서비스').</p>
        <p><strong>• </strong>'서비스'라는 용어는 웹사이트, 웹 서비스, 모바일 앱, 기기 및 사용 가능한 서비스를 의미합니다.</p>
        <p>개인정보 보호정책은 당사가 누구인지, 귀하에 대해 수집하는 정보, 귀하의 개인 정보를 사용하고 공개하는 방법, 귀하의 정보를 보호하는 방법에 대한 세부 정보 및 귀하의 데이터 개인 정보 보호
            권리를 설명합니다. 당사 서비스는 귀하의 개인 정보를 매우 중요하게 여기며 귀하에게 개인 정보에 대한 통제권을 제공합니다. 당사는 강력한 암호화 기술을 사용하여 귀하의 개인 데이터와 개인 정보를
            보호하고 모든 데이터를 관리하기 위한 엄격한 정책을 사용합니다. 당사는 항상 소위 일반 데이터 보호 규정("GDPR") 및 캘리포니아 소비자 개인 정보 보호법("CCPA")에 따라 귀하와 관련된
            개인 데이터를 보호합니다.</p>
        <p><strong>A. 회사 소개</strong></p>
        <p>서비스는 세금 ID가 있는 SMART-PLUS PTE. LTD. a.s.에서 제공하고 관리합니다. 등록번호 202116626G 및 싱가포르(048580)의 RAFFLES QUAY #14-06에
            등록된 사무실이 있습니다.</p>
        <p><strong>B. 수집하는 개인 정보</strong></p>
        <p>개인 정보는 해당 법률에 따라 서비스를 제공하기 위해 당사 서비스에서 수집합니다.</p>
        <p>"개인 정보"는 "개인 데이터"와 동일한 의미를 가지며 식별되거나 식별 가능한 자연인("데이터 주체")과 관련된 모든 정보를 포함합니다. 식별 가능한 개인은 특히 식별 번호 또는 신체적, 생리적,
            정신적, 경제적, 문화적 또는 사회적 정체성에 대한 하나 이상의 특정 요소를 참조하여 직접 또는 간접적으로 식별될 수 있는 사람입니다.</p>
        <p>당사는 귀하에게 서비스를 제공하는 데 필요한 개인 정보를 제공해 달라고 요청할 것입니다. 귀하가 이러한 정보 중 일부 또는 전부를 제공하지 않으면 당사는 이를 처리하지 않지만 서비스 또는 일부
            기능이 제한될 수 있습니다.</p>
        <p>다음 부분에서는 귀하가 당사 서비스를 사용할 때 당사가 수집하는 정보 유형을 이해하시기를 바랍니다.</p>
        <p><strong>1. 사용자 계정 등록</strong></p>
        <p>서비스 계정을 만들려면 등록 과정에서 다음 개인 정보를 제공해야 합니다.</p>
        <p><strong>건물 관리자 계정:</strong></p>
        <p> <strong>•</strong> 이름</p>
        <p> <strong>•</strong> 성</p>
        <p> <strong>•</strong> 이메일 </p>
        <p><strong>사용자 계정:</strong></p>
        <p> <strong>•</strong> 이름</p>
        <p> <strong>•</strong> 성</p>
        <p> <strong>•</strong> 이메일 </p>
        <p> <strong>•</strong> 휴대전화 </p>
        <p> <strong>•</strong> 주소(거주 사용자인 경우 주거 주소를 수집하고, 사업체 사용자인 경우 사업체 주 소를 수집합니다.)</p>
        <p><strong>2. 서비스 사용</strong></p>
        <p>서비스를 사용하는 동안 당사는 아래의 개인 정보(또는 일부만)를 수집합니다.</p>
        <p><strong>• </strong>휴대전화 IP 주소</p>
        <p><strong>•</strong> 휴대전화 Bluetooth 정보</p>
        <p>• 기기 이름</p>
        <p>• 기기 IP 주소 및 Mac 주소</p>
        <p>• 기기 및 휴대전화 위치</p>
        <p>• 인증 정보</p>
        <p>• 휴대전화 앱을 통해 처리되고 클라우드에 저장된 이미지, 비디오 및 오디오</p>
        <p>기기 및 시스템의 구성 변경</p>
        <p>• 로그 정보, 특히 통화 기록, 문 열림 스냅샷, 문 열림 내역 및 자동화 내역</p>
        <p>• 구독 상태</p>
        <p><strong>3. 문제 해결 및 개선 서비스</strong></p>
        <p>서비스를 사용하는 동안 발생하는 문제를 해결하고 서비스를 최적화하기 위해 모바일 앱을 사용하는 동안 다음 정보를 수집합니다.</p>
        <p>• 마지막 로그인</p>
        <p>• 사용 기간</p>
        <p>• 오디오 및 화상 통화</p>
        <p>• 시스템 로그</p>
        <p>• 설치된 애플리케이션 데이터</p>
        <p>• 개인 정보(예: 연락처 이름)가 포함된 경우 구성 다운로드</p>
        <p><strong>4. 서비스 구독</strong></p>
        <p>서비스 비용을 지불하기로 선택한 경우 다음 개인 정보가 제공됩니다.</p>
        <p><strong>•</strong> 이름</p>
        <p>• 성</p>
        <p>• 이메일 </p>
        <p>• 거주 국가</p>
        <p>• 주소</p>
        <p>• 결제 세부 정보</p>
        <p>이러한 개인 데이터 처리가 귀하에게 서비스를 제공하기 위해 필요하며 귀하와 체결한 계약을 이행하는 데 필요합니다.</p>
        <p><strong>6. 타사 서비스</strong></p>
        <p>당사 서비스를 통해 타사 서비스를 사용하는 경우, 당사는 귀하의 요청에 응답하고, 요청된 서비스를 제공하고, 당사 서비스를 개선하기 위해 개인 정보를 처리합니다. 서비스 제공의 일환으로, 맞춤형
            콘텐츠를 제공하기 위해 당사는 이러한 타사 서비스와의 계약을 통해 사용자를 식별하고 관리하기 위한 개인 정보를 수신합니다.</p>
        <p>소셜 공유 기능을 제공하기 위해 MobTech ShareSDK와 통합하여 시스템 정보, 네트워크 상태, IMEI, OAID, IMSI, 앱 목록, 휴대전화 기지국, 소셜 플랫폼 OpenID,
            휴대전화 지리적 위치와 같은 개인 정보를 수집합니다. 자세한 내용은 www.mob.com/about/policy에서 관련 개인정보 보호정책 및 약관을 참조하세요.</p>
        <p>타사 서비스 사용은 이러한 타사 이용약관 또는 서비스 및 해당 개인정보 보호 고지의 적용을 받습니다. 기기에서 이러한 타사 약관을 활성화하기 전에 주의 깊게 읽어 보시기 바랍니다. 귀하의 개인
            정보 처리가 귀하와의 계약을 이행하는 데 필요하며, 당사의 합법적 이익을 위해 필요합니다.</p>
        <p>또한 타사 서비스에서 귀하에 대한 정보를 수집할 수도 있습니다. 이러한 개인 데이터 처리가 귀하에게 서비스를 제공하는 데 필요하므로 귀하와의 계약을 이행하는 데 필요합니다.</p>
        <p><strong>중요:</strong> 언제든지(어떤 이유나 편의를 위해) 이 데이터 수집에 반대할 수 있습니다. 그런 경우 당사는 이 목적을 위한 개인 데이터 수집을 즉시 중단합니다.</p>
        <p><strong>C. 쿠키 및 유사 기술</strong></p>
        <p>쿠키 및 유사 기술은 인터넷에서 일반적으로 사용되는 기술입니다. 이는 웹사이트를 방문할 때 컴퓨터로 전송되는 문자열이 포함된 작은 파일입니다. 웹사이트를 다시 방문하면 쿠키를 통해 웹사이트가
            귀하의 브라우저를 인식할 수 있습니다. 쿠키는 사용자 기본 설정 및 기타 정보도 저장할 수 있습니다. 브라우저를 재설정하여 모든 쿠키를 거부하거나 웹사이트가 귀하의 컴퓨터로 쿠키를 보낼 때
            알림을 받을 수 있습니다. 그러나 쿠키가 없으면 일부 웹사이트 기능이나 서비스가 제대로 작동하지 않을 수 있습니다.</p>
        <p>귀하가 웹사이트 및 모바일 앱에 액세스하여 사용할 때 당사는 이러한 기술을 사용하여 귀하의 기기로 쿠키 또는 익명 식별자를 전송하여 정보를 수집하고 저장할 수 있습니다. 쿠키 및 기타 기술을 통해
            수집된 정보는 귀하의 현지 법률이 인터넷 프로토콜(IP) 주소 또는 유사한 식별 표시를 개인 정보로 취급하지 않는 한 비개인 정보로 취급합니다. 당사는 다음 서비스에 쿠키 및 유사한 기술을
            사용합니다.</p>
        <p><strong>1. 서비스의 안전하고 효율적인 운영을 보장하기 위해</strong></p>
        <p>당사는 귀하가 서비스에 안전하게 로그인했는지 확인하거나 사기와 같은 불법 행위에 직면했는지 여부를 평가하는 데 도움이 되는 인증 또는 보안 쿠키 또는 익명 식별자를 설정할 수 있습니다. 이러한
            기술은 또한 서비스 효율성과 시스템 로그인 및 응답 속도를 개선하는 데 도움이 됩니다.</p>
        <p><strong>2. 보다 쉬운 액세스 경험을 제공하기 위해</strong></p>
        <p>이러한 기술을 사용하면 원클릭 로그인 및 검색 기록과 같은 방법을 통해 개인 정보를 반복적으로 입력하고 검색 내용을 입력하는 단계와 프로세스를 피할 수 있습니다.</p>
        <p><strong>3. 로그 파일용</strong></p>
        <p>대부분의 웹사이트와 마찬가지로 당사는 특정 정보를 수집하여 로그 파일에 저장합니다. 이러한 정보에는 인터넷 프로토콜(IP) 주소, 브라우저 유형, 인터넷 서비스 공급자(ISP), 참조자/종료
            페이지, 운영 체제, 날짜/시간 스탬프 및/또는 클릭 스트림 데이터가 포함될 수 있습니다. 당사는 자동으로 수집된 로그 데이터를 당사가 수집하는 다른 정보와 연관시키지 않습니다.</p>
        <p><strong>4. 모바일 분석의 경우</strong></p>
        <p>웹사이트 및 모바일 앱의 경우 모바일 분석 소프트웨어를 사용하여 휴대폰에서 모바일 소프트웨어의 기능을 더 잘 이해합니다. 이 소프트웨어는 귀하가 애플리케이션을 사용하는 빈도, 애플리케이션 내에서
            발생하는 이벤트, 누적 사용, 성능 데이터 및 애플리케이션 충돌이 발생한 위치 데이터와 같은 정보를 기록할 수 있습니다. 당사는 분석 소프트웨어에 저장된 정보를 귀하가 웹사이트 및 모바일 앱에
            제출한 개인 정보와 연관시키지 않습니다.</p>
        <p><strong>D. 개인 정보 사용 방법</strong></p>
        <p>당사는 수집한 개인 정보를 다음과 같은 목적으로 사용할 수 있습니다.</p>
        <p><strong>1</strong>. 귀하에게 서비스를 제공하기 위해. 당사는 귀하의 개인 정보를 사용하여 귀하가 서비스를 사용할 수 있도록 합니다. 예를 들어 방문자가 인터컴을 통해 귀하에게
            전화를 걸 수 있도록 합니다.</p>
        <p><strong>2</strong>. 문제 해결 및 유지 관리를 수행합니다. 당사는 사용자가 설치한 애플리케이션 데이터를 https://maintenance.akuvox.com에 업로드하여
            애플리케이션의 문제를 분석하고 진단하여 서비스 경험을 개선합니다.</p>
        <p><strong>3.</strong> 마케팅 목적으로 귀하와 소통하기 위해 귀하의 개인 정보를 사용할 수 있습니다. 예를 들어, 서비스 설문 조사 수행, 귀하의 질문에 답변, 새로운 서비스 홍보.
        </p>
        <p><strong>4.</strong> 서비스 개선을 위해 귀하의 웹사이트 및 모바일 앱 사용과 관련된 통계 정보의 데이터 분석, 연구 및 개발과 같은 내부적 사용.</p>
        <p><strong>5.</strong> 서비스의 공유 기능을 강화하기 위해 귀하의 기기에 설치된 애플리케이션에 대한 정보를 수집할 수 있습니다. 이 정보는 이러한 애플리케이션을 통해 콘텐츠를 공유하고
            보다 편리한 공유 옵션을 제공하기 위해서만 사용됩니다.</p>
        <p><strong>6.</strong> 소프트웨어 검증 및 서비스 업그레이드를 수행합니다.</p>
        <p><strong>7.</strong> 법률을 준수하고 당사의 권리를 행사합니다. 당사는 적용 가능한 법률을 평가하고 준수를 보장하고, 자산을 보호하거나, 불법 또는 위법 행위에 대한 주장을
            조사하거나 방어하고(법적 조언을 받거나 법적 권리를 확립, 행사 또는 방어하는 것을 포함) 법원 명령이나 사법 또는 기타 정부 소환장 또는 수색 영장에 응답하기 위해 합리적으로 필요한 경우
            귀하의 개인 정보를 사용할 수 있습니다.</p>
        <p><strong>E. 귀하의 개인 정보를 공개하는 방법</strong></p>
        <p>당사는 어떠한 목적으로도 누구에게도 개인 정보를 판매하지 않습니다. 당사는 귀하의 개인 정보를 다음 범주의 수신자에게 공개할 수 있습니다.</p>
        <p><strong>1. 서비스 제공자</strong></p>
        <p>당사는 귀하의 지역 시장에서 당사의 사업 운영을 지원하는 서비스를 제공하는 제3자 서비스 제공자에게 귀하의 정보를 공개할 수 있습니다. 여기에는 집주인, 부동산 관리자 및 당사의 지역 사업
            파트너가 포함될 수 있습니다.</p>
        <p><strong>2. 법 집행</strong></p>
        <p>법률상 요구되거나 그러한 사용이 법적 의무, 처리 또는 요청을 준수하는 데 합리적으로 필요한 경우 법 집행 기관, 공공 기관 또는 기타 조직과 귀하의 정보를 공유합니다. 데이터 전송 프로세스 중에
            귀하의 정보 보안을 위해 데이터를 암호화합니다.</p>
        <p><strong>F. 개인 데이터 저장 및 개인 데이터 전송</strong></p>
        <p>당사 서비스의 인프라는 북부 캘리포니아, 미국, 프랑크푸르트 독일, 싱가포르와 같은 여러 장소에 있는 웹 서비스에서 실행되며 모든 개인 데이터는 해당 서버에 저장됩니다. 귀하의 개인 데이터는
            서버에서 Amazon Cloud 또는 Alibaba Cloud로 백업됩니다. 데이터 전송 프로세스 중에 귀하의 데이터 보안을 위해 귀하의 데이터를 암호화하고 보호합니다.</p>
        <p>이 개인정보 보호정책에 설명된 목적을 위해 해당 정보를 처리할 때 요청된 서비스를 제공하기 위해 귀하의 정보의 특정 부분에 액세스할 수 있습니다.당사의 기업 콘텐츠 데이터 관리 규칙은 모든 데이터
            전송이 해당 지역 법률 및 규정을 준수하도록 합니다.</p>
        <p><strong>G. 귀하의 권리 및 선택</strong></p>
        <p>귀하는 당사 서비스를 사용할 때 귀하의 개인 데이터에 대해 다음과 같은 권리를 갖습니다.</p>
        <p><strong>접근 권한</strong></p>
        <p>귀하는 귀하의 개인 데이터가 처리되고 있는지 여부에 대한 확인을 받을 권리가 있습니다. 그리고 당사가 귀하의 데이터를 처리하는 경우 귀하는 개인 데이터와 다음 정보에 접근할 권리가 있습니다.
        </p>
        <p>a) 처리 목적;</p>
        <p>b) 관련 개인 데이터의 범주;</p>
        <p>c) 개인 데이터가 공개되었거나 공개될 수신자 또는 수신자 범주, 특히 제3국 또는 국제 기구의 수신자;</p>
        <p>d) 가능한 경우 개인 데이터가 저장될 예상 기간 또는 불가능한 경우 해당 기간을 결정하는 데 사용된 기준;</p>
        <p>e) 통제자에게 귀하와 관련된 개인 데이터의 수정 또는 삭제 또는 개인 데이터 처리 제한을 요청하거나 해당 처리에 반대할 권리의 존재;</p>
        <p>f) 감독 기관에 불만을 제기할 권리;</p>
        <p>g) 개인 데이터가 귀하에게서 수집되지 않은 경우 출처에 대한 사용 가능한 정보;</p>
        <p>h) GDPR 제22조(1)항 및 (4)항에 언급된 프로파일링을 포함한 자동화된 의사결정의 존재 및 적어도 그러한 경우 관련 논리에 대한 의미 있는 정보, 그리고 귀하에게 그러한 처리의 중요성과
            예상되는 결과</p>
        <p>개인 데이터가 제3국 또는 국제 기구로 이전되는 경우, 귀하는 이전과 관련하여 적절한 보호 조치에 대해 알 권리가 있습니다.</p>
        <p><strong>정정 권리</strong></p>
        <p>귀하는 귀하에 대한 부정확한 개인 데이터를 지체 없이 정정할 권리가 있습니다. 처리 목적을 고려할 때, 귀하는 보충 진술을 제공하는 것을 포함하여 불완전한 개인 데이터를 완성할 권리가 있습니다.
        </p>
        <p><strong>삭제 권리("잊힐 권리")</strong></p>
        <p>귀하는 다음 조건 중 하나 이상이 충족되는 경우 지체 없이 귀하에 대한 개인 데이터를 삭제할 권리가 있습니다.</p>
        <p>a) 귀하의 개인 데이터가 수집 또는 기타 방식으로 처리된 목적과 관련하여 더 이상 필요하지 않은 경우;</p>
        <p>b) 귀하의 개인 데이터가 귀하의 동의에 따라 처리되었고 귀하가 그러한 동의를 철회했으며 당사가 처리를 계속할 수 있는 다른 법적 근거가 없는 경우;</p>
        <p>c) 귀하가 처리에 반대하고 처리를 위한 우선하는 합법적 근거가 없는 경우(예: 마케팅 목적으로 귀하와 관련된 개인 데이터를 처리하는 것에 반대할 수 있는 경우);</p>
        <p>d) 귀하의 개인 데이터가 불법적으로 처리된 경우;</p>
        <p>e) 귀하의 개인 데이터는 당사가 따르는 법적 의무를 준수하기 위해 삭제되어야 합니다.</p>
        <p>귀하와 관련된 개인 데이터를 삭제하기를 원하고 위에 명시된 조건 중 하나 이상이 적용되는지 또는 제한 사항이 있는지 확실하지 않은 경우 당사에 문의하시면 가능한 한 최선의 방법으로 귀하의 요청을
            해결하기 위해 도움을 드리겠습니다.</p>
        <p><strong>처리 제한 권리</strong></p>
        <p>귀하는 다음 중 하나가 적용되는 경우 처리에 대한 제한을 받을 권리가 있습니다.</p>
        <p>a) 귀하가 개인 데이터의 정확성에 이의를 제기하는 경우</p>
        <p>b) 처리가 불법적이고 귀하가 개인 데이터 삭제에 반대하고 대신 사용 제한을 요청하는 경우</p>
        <p>c) 당사가 더 이상 처리 목적을 위해 개인 데이터를 필요로 하지 않지만 귀하가 법적 청구를 설정, 행사 또는 방어하기 위해 필요로 하는 경우</p>
        <p>d) 귀하가 처리에 반대하고 귀하의 반대가 현재 당사의 합법적 근거가 귀하의 정당한 근거보다 우선하는지 여부를 확인하는 중입니다.</p>
        <p>처리가 제한된 경우, 저장을 제외하고 해당 개인 데이터는 귀하의 동의가 있거나 법적 청구의 설정, 행사 또는 방어 또는 다른 자연인 또는 법인의 권리 보호를 위해서만 처리됩니다.</p>
        <p><strong>데이터 이동성에 대한 권리</strong></p>
        <p>귀하는 귀하에 대한 일부 개인 데이터를 구조화되고 일반적으로 사용되며 기계에서 읽을 수 있는 형식으로 수신하고 방해 없이 다른 관리자에게 전송할 권리가 있습니다. 이 권리는 다음과 같은 귀하에
            대한 개인 데이터에 적용됩니다.</p>
        <p>a) 귀하로부터 수집한 경우,</p>
        <p>b) 귀하의 동의 또는 계약에 따라 처리된 경우,</p>
        <p>c) 자동화된 수단으로 처리된 경우</p>
        <p>위의 기준을 충족하는 개인 데이터를 처리하는 경우 기술적으로 가능한 경우 귀하는 해당 개인 데이터를 다른 관리자에게 직접 전송할 권리가 있습니다.</p>
        <p><strong>이의를 제기할 권리</strong></p>
        <p>귀하는 예를 들어 직접 마케팅 목적으로 귀하에 대한 개인 데이터를 처리하는 것에 이의를 제기할 권리가 있습니다. 귀하의 이의를 신중하게 평가하고 처리에 대한 강력한 합법적 근거가 없는 경우 개인
            데이터 처리를 중단합니다.</p>
        <p><strong>H. 개인 데이터 보안</strong></p>
        <p>당사 서비스에는 강력한 내장 보안 기능이 있습니다. 당사는 엄격한 정보 관리 정책을 개발하고, 전문 기술 팀을 갖추고 있으며, 다음을 포함하여 개인 정보를 무단 탐색, 공개, 남용, 변경, 파괴
            및 손실로부터 보호하기 위한 일련의 합리적인 예방 조치를 채택합니다.</p>
        <p>1. 암호화 기술을 사용하여 전송 중에 데이터가 비공개로 유지되도록 합니다.</p>
        <p>2. 계정 보안을 보호하는 데 도움이 되는 여러 보안 기능을 제공합니다.</p>
        <p>3. 물리적 보안 조치를 포함하여 정보 수집, 저장 및 처리 관행을 검토하여 무단 사용자가 당사 시스템에 액세스하지 못하도록 합니다.</p>
        <p><strong>I. 개인 데이터 보관 기간</strong></p>
        <p>당사는 수집한 개인 데이터를 그 내용과 사용 방법에 따라 다른 기간 동안 보관합니다. 다양한 유형의 데이터 세트를 다른 기간 동안 보관하는 이유를 설명합니다.</p>
        <p>1. 당사는 수집 이유에 따라 다양한 유형의 데이터에 대해 서로 다른 보관 기간을 설정합니다(예: 문 열림 로그 및 통화 내역). 1개월 후에는 데이터가 자동으로 삭제됩니다.</p>
        <p>2. 계정이 삭제될 때까지 보관되는 개인 정보, 예: 사용자 계정 정보.</p>
        <p>3. 제한된 목적을 위해 장기간 보관되는 정보. 때때로 사업, 법률 또는 규제 요건으로 인해 특정 목적을 위해 장기간 특정 정보를 보관해야 할 수도 있습니다.</p>
        <p><strong>J. 아동의 개인 정보 보호</strong></p>
        <p>당사는 18세 미만의 아동이 당사 서비스에 액세스하거나 사용하도록 의도하지 않으며 이러한 사용은 금지됩니다. 만 18세 미만인 경우 언제든지 어떤 방식으로든 서비스를 사용하거나 액세스하지
            마십시오. 당사가 18세 미만의 등록 사용자와 계정이 연결되어 있다는 실제 지식을 얻은 경우 해당 계정과 관련된 정보를 즉시 삭제합니다. 부모 또는 보호자이고 자녀가 귀하의 동의 없이 당사에
            개인 정보를 제공했다는 사실을 발견한 경우 [서비스 제공업체: 이메일]으로 연락하여 당사 시스템에서 해당 정보를 삭제하도록 요청하십시오.</p>
        <p><strong>K. 이 개인정보 보호정책에 대한 업데이트</strong></p>
        <p>당사는 이 개인정보 보호정책을 수시로 수정하고 업데이트할 수 있습니다. 당사는 웹사이트 및 모바일 앱에 알림 및 기타 양식을 통해 개인정보 보호정책에 대한 업데이트를 게시합니다. 모바일 앱을
            방문하거나 당사 웹사이트 https://www.akuvoxsmartplus.com을 방문하여 최신 버전의 개인정보 보호정책을 확인할 수 있습니다. 당사는 주요 변경 사항에 대한 공식 알림을
            보내드립니다(특정 서비스의 경우 개인정보 보호정책 변경에 대한 이메일 알림 포함).</p>
        <p><strong>L. 문의처</strong></p>
        <p>당사의 개인 데이터 사용 또는 해당 권리 행사와 관련하여 다른 질문이나 요구 사항이 있거나 추가 정보가 필요한 경우 [서비스 제공자.회사]으로 문의하실 수 있습니다. 귀하의 이름(또는 별명),
            선호하는 연락 방법, 요청 또는 의견의 세부 정보와 해당 웹사이트 링크를 제공해 주십시오.</p>
        <br />
        <br />
        <br />
        <br />
        <br />
    </div>
</template>

<style lang="scss" scoped>
p {
    margin-top: 10px;
}

.policy-ul {
    left: 20px;
    position: relative;
    width: 90%;

    li {
        list-style: disc;
    }
}

h3 {
    margin-top: 10px;
}
</style>
